import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GRAPH_CMS_CLIENT_NAME } from '../constants';
import { GET_WHITELABEL_CONFIG } from '../../apollo/cms/GraphCMS/getWhitelabelConfig';
import {
  getWhitelabelConfig,
  getWhitelabelConfigVariables,
  Language,
} from '../../apollo/cms/GraphCMS/__generatedTypes';
import nookies from 'nookies';
import { isDev, isProduction, isStaging } from '../utils/helpers';
import { i18n } from '@lingui/core';

export type CommunityLink = {
  url: string;
  label: string;
} | null;

export type WhiteLabelContext = {
  logo?: string;
  loading: boolean;
  domainName: string;
  communityLink: CommunityLink;
  customPartnersLang?: Language;
  language?: Language;
  isLinkToPartnersVisible: boolean;
  linksToQuiz?: {
    [key: string]: {
      url: string;
      buttonLabel: string;
    };
  };
  linkToFeedbackForm: {
    url: string;
    label: string;
  };
};
type UseWhiteLabelContext = () => WhiteLabelContext;

const WhiteLabelContext = React.createContext<WhiteLabelContext>(
  {} as WhiteLabelContext
);

export const useWhiteLabelContext: UseWhiteLabelContext = () =>
  useContext(WhiteLabelContext);
type Props = { children: ReactNode };
export const WhiteLabelProvider = ({ children }: Props) => {
  const [domainName, setDomainName] = useState('');

  useEffect(() => {
    //if length === 3 it is on subdomain e.g. innovateslovakia.startupbox.app
    //else it is defaul startupbox.app
    const subdomainGetter =
      window.location.host.split('.').length === 3
        ? window.location.host.split('.')[0]
        : 'default';

    if (isProduction) {
      setDomainName(subdomainGetter);
    }

    if (isDev || isStaging) {
      setDomainName('staging');
    }

    nookies.set({}, 'domain', window.location.href);
  }, []);

  const { data, loading } = useQuery<
    getWhitelabelConfig,
    getWhitelabelConfigVariables
  >(GET_WHITELABEL_CONFIG, {
    skip: !domainName,
    variables: { client: domainName },
    context: {
      clientName: GRAPH_CMS_CLIENT_NAME,
    },
  });

  if (data?.whitelabel?.defaultLanguage) {
    i18n.activate(data?.whitelabel?.defaultLanguage);
  }

  // TODO: change customPartners to lang and remove customPartners ?

  return (
    <WhiteLabelContext.Provider
      value={{
        loading,
        domainName,
        logo: data?.whitelabel?.logo?.url,
        language: data?.whitelabel?.defaultLanguage ?? Language.cs,
        customPartnersLang:
          (data?.whitelabel?.customPartners as Language) ?? Language.cs,
        communityLink: data?.whitelabel
          ? {
              url: data?.whitelabel.communityLink,
              label: data?.whitelabel.communityLinkLabel,
            }
          : null,
        linksToQuiz: data?.whitelabel?.linksToQuiz,
        //TODO: fix this, type fpr linkToFeedbackForm, can't generate, blocker apollo v node
        // @ts-ignore
        linkToFeedbackForm: data?.whitelabel?.linkToFeedbackForm,
        // @ts-ignore
        isLinkToPartnersVisible: data?.whitelabel?.isLinkToPartnersVisible,
      }}
    >
      {children}
    </WhiteLabelContext.Provider>
  );
};
