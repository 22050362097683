/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAsset
// ====================================================

export interface deleteAsset_deleteAsset {
  __typename: 'Asset';
  /**
   * The unique identifier
   */
  id: string;
}

export interface deleteAsset {
  /**
   * Delete one asset from _all_ existing stages. Returns deleted document.
   */
  deleteAsset: deleteAsset_deleteAsset | null;
}

export interface deleteAssetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllContentPagesSlugs
// ====================================================

export interface getAllContentPagesSlugs_contentPages {
  __typename: 'ContentPage';
  /**
   * The unique identifier
   */
  id: string;
  slug: string;
}

export interface getAllContentPagesSlugs {
  /**
   * Retrieve multiple contentPages
   */
  contentPages: getAllContentPagesSlugs_contentPages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContentPageBasic
// ====================================================

export interface GetContentPageBasic_contentPages_mainVideo {
  __typename: 'Video';
  url: string;
  mainActor: string | null;
}

export interface GetContentPageBasic_contentPages_mainRichText_references {
  __typename: 'Video';
  /**
   * The unique identifier
   */
  id: string;
  url: string;
  mainActor: string | null;
}

export interface GetContentPageBasic_contentPages_mainRichText {
  __typename: 'ContentPageMainRichTextRichText';
  json: any;
  references: GetContentPageBasic_contentPages_mainRichText_references[];
}

export interface GetContentPageBasic_contentPages_contentPageSections {
  __typename: 'ContentPageSection';
  title: string | null;
}

export interface GetContentPageBasic_contentPages {
  __typename: 'ContentPage';
  /**
   * The unique identifier
   */
  id: string;
  slug: string;
  title: string;
  mainVideo: GetContentPageBasic_contentPages_mainVideo | null;
  mainRichText: GetContentPageBasic_contentPages_mainRichText | null;
  contentPageSections: GetContentPageBasic_contentPages_contentPageSections[];
}

export interface GetContentPageBasic {
  /**
   * Retrieve multiple contentPages
   */
  contentPages: GetContentPageBasic_contentPages[];
}

export interface GetContentPageBasicVariables {
  slug: string;
  stage: Stage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContentPageFull
// ====================================================

export interface GetContentPageFull_contentPages_mainVideo {
  __typename: 'Video';
  url: string;
  mainActor: string | null;
}

export interface GetContentPageFull_contentPages_mainRichText_references {
  __typename: 'Video';
  /**
   * The unique identifier
   */
  id: string;
  url: string;
  mainActor: string | null;
}

export interface GetContentPageFull_contentPages_mainRichText {
  __typename: 'ContentPageMainRichTextRichText';
  json: any;
  references: GetContentPageFull_contentPages_mainRichText_references[];
}

export interface GetContentPageFull_contentPages_contentPageSections_richText_references {
  __typename: 'Video';
  /**
   * The unique identifier
   */
  id: string;
  url: string;
  mainActor: string | null;
}

export interface GetContentPageFull_contentPages_contentPageSections_richText {
  __typename: 'ContentPageSectionRichTextRichText';
  json: any;
  references: GetContentPageFull_contentPages_contentPageSections_richText_references[];
}

export interface GetContentPageFull_contentPages_contentPageSections_contentPageSectionSponsor_logo {
  __typename: 'Asset';
  /**
   * The unique identifier
   */
  id: string;
  /**
   * Get the url for the asset with provided transformations applied.
   */
  url: string;
}

export interface GetContentPageFull_contentPages_contentPageSections_contentPageSectionSponsor {
  __typename: 'ContentPageSectionSponsor';
  link: string | null;
  logo: GetContentPageFull_contentPages_contentPageSections_contentPageSectionSponsor_logo;
}

export interface GetContentPageFull_contentPages_contentPageSections_contentPageSectionItems_image {
  __typename: 'Asset';
  /**
   * The unique identifier
   */
  id: string;
  /**
   * Get the url for the asset with provided transformations applied.
   */
  url: string;
}

export interface GetContentPageFull_contentPages_contentPageSections_contentPageSectionItems {
  __typename: 'ContentPageSectionItem';
  /**
   * The unique identifier
   */
  id: string;
  title: string;
  link: string | null;
  image: GetContentPageFull_contentPages_contentPageSections_contentPageSectionItems_image | null;
  description: string | null;
}

export interface GetContentPageFull_contentPages_contentPageSections {
  __typename: 'ContentPageSection';
  /**
   * The unique identifier
   */
  id: string;
  itemsLayout: ContentPageSectionLayout;
  richText: GetContentPageFull_contentPages_contentPageSections_richText | null;
  title: string | null;
  contentPageSectionSponsor: GetContentPageFull_contentPages_contentPageSections_contentPageSectionSponsor | null;
  contentPageSectionItems: GetContentPageFull_contentPages_contentPageSections_contentPageSectionItems[];
}

export interface GetContentPageFull_contentPages {
  __typename: 'ContentPage';
  /**
   * The unique identifier
   */
  id: string;
  slug: string;
  title: string;
  mainVideo: GetContentPageFull_contentPages_mainVideo | null;
  mainRichText: GetContentPageFull_contentPages_mainRichText | null;
  contentPageSections: GetContentPageFull_contentPages_contentPageSections[];
}

export interface GetContentPageFull {
  /**
   * Retrieve multiple contentPages
   */
  contentPages: GetContentPageFull_contentPages[];
}

export interface GetContentPageFullVariables {
  slug: string;
  stage: Stage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDashboardBanner
// ====================================================

export interface getDashboardBanner_dashboardBanners_desktopImage {
  __typename: 'Asset';
  /**
   * The unique identifier
   */
  id: string;
  /**
   * System stage field
   */
  stage: Stage;
  /**
   * The file width
   */
  width: number | null;
  /**
   * The height of the file
   */
  height: number | null;
  /**
   * The file size
   */
  size: number | null;
  /**
   * Get the url for the asset with provided transformations applied.
   */
  url: string;
}

export interface getDashboardBanner_dashboardBanners_mobileImage {
  __typename: 'Asset';
  /**
   * The unique identifier
   */
  id: string;
  /**
   * System stage field
   */
  stage: Stage;
  /**
   * The file width
   */
  width: number | null;
  /**
   * The height of the file
   */
  height: number | null;
  /**
   * The file size
   */
  size: number | null;
  /**
   * Get the url for the asset with provided transformations applied.
   */
  url: string;
}

export interface getDashboardBanner_dashboardBanners {
  __typename: 'DashboardBanner';
  /**
   * The unique identifier
   */
  id: string;
  name: string;
  /**
   * System stage field
   */
  stage: Stage;
  desktopImage: getDashboardBanner_dashboardBanners_desktopImage;
  link: string;
  mobileImage: getDashboardBanner_dashboardBanners_mobileImage;
}

export interface getDashboardBanner {
  /**
   * Retrieve multiple dashboardBanners
   */
  dashboardBanners: getDashboardBanner_dashboardBanners[];
}

export interface getDashboardBannerVariables {
  name?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPartners
// ====================================================

export interface getPartners_partners {
  __typename: 'Partner';
  /**
   * The unique identifier
   */
  id: string;
  link: string;
  logo: string;
  name: string;
  /**
   * Seznam tagů oddělených čárkou
   */
  tags: string | null;
  description: string;
}

export interface getPartners {
  /**
   * Retrieve multiple partners
   */
  partners: getPartners_partners[];
}

export interface getPartnersVariables {
  where?: PartnerWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSpecialOffers
// ====================================================

export interface getSpecialOffers_specialOffers {
  __typename: 'SpecialOffer';
  /**
   * The unique identifier
   */
  id: string;
  description: string;
  link: string;
  logo: string;
  name: string;
  subtitle: string;
  /**
   * Seznam tagů oddělených čárkou
   */
  tags: string | null;
}

export interface getSpecialOffers {
  /**
   * Retrieve multiple specialOffers
   */
  specialOffers: getSpecialOffers_specialOffers[];
}

export interface getSpecialOffersVariables {
  where?: SpecialOfferWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWhitelabelConfig
// ====================================================

export interface getWhitelabelConfig_whitelabel_logo {
  __typename: 'Asset';
  /**
   * The height of the file
   */
  height: number | null;
  /**
   * Get the url for the asset with provided transformations applied.
   */
  url: string;
  /**
   * The file width
   */
  width: number | null;
}

export interface getWhitelabelConfig_whitelabel {
  __typename: 'Whitelabel';
  /**
   * Client is the subdomain. Name has to exactly match the subdomain
   */
  client: string;
  /**
   * Optionally set default language of the app
   */
  defaultLanguage: Language | null;
  communityLink: string;
  communityLinkLabel: string;
  customPartners: string | null;
  linksToQuiz: any | null;
  logo: getWhitelabelConfig_whitelabel_logo | null;
}

export interface getWhitelabelConfig {
  /**
   * Retrieve a single whitelabel
   */
  whitelabel: getWhitelabelConfig_whitelabel | null;
}

export interface getWhitelabelConfigVariables {
  client?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: registrationPromoResults
// ====================================================

export interface registrationPromoResults_registrationPromoResults {
  __typename: 'RegistrationPromoResult';
  /**
   * The unique identifier
   */
  id: string;
  count: string;
  textCs: string;
  textEn: string | null;
  textSk: string | null;
  countColor: string;
}

export interface registrationPromoResults {
  /**
   * Retrieve multiple registrationPromoResults
   */
  registrationPromoResults: registrationPromoResults_registrationPromoResults[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContentPageSectionLayout {
  Cards = 'Cards',
  ListOnCard = 'ListOnCard',
  PartnersFlatCards = 'PartnersFlatCards',
  SecondaryRichText = 'SecondaryRichText',
}

export enum Language {
  cs = 'cs',
  en = 'en',
  sk = 'sk',
}

/**
 * System Scheduled Operation Status
 */
export enum ScheduledOperationStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

/**
 * System Scheduled Release Status
 */
export enum ScheduledReleaseStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

/**
 * Stage system enumeration
 */
export enum Stage {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

/**
 * System User Kind
 */
export enum UserKind {
  MEMBER = 'MEMBER',
  PAT = 'PAT',
  PUBLIC = 'PUBLIC',
  WEBHOOK = 'WEBHOOK',
}

/**
 * This contains a set of filters that can be used to compare values internally
 */
export interface PartnerWhereComparatorInput {
  outdated_to?: boolean | null;
}

/**
 * Identifies documents
 */
export interface PartnerWhereInput {
  _search?: string | null;
  AND?: PartnerWhereInput[] | null;
  OR?: PartnerWhereInput[] | null;
  NOT?: PartnerWhereInput[] | null;
  documentInStages_every?: PartnerWhereStageInput | null;
  documentInStages_some?: PartnerWhereStageInput | null;
  documentInStages_none?: PartnerWhereStageInput | null;
  publishedAt?: any | null;
  publishedAt_not?: any | null;
  publishedAt_in?: (any | null)[] | null;
  publishedAt_not_in?: (any | null)[] | null;
  publishedAt_lt?: any | null;
  publishedAt_lte?: any | null;
  publishedAt_gt?: any | null;
  publishedAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: (any | null)[] | null;
  updatedAt_not_in?: (any | null)[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: (any | null)[] | null;
  createdAt_not_in?: (any | null)[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: (string | null)[] | null;
  name_not_in?: (string | null)[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  link?: string | null;
  link_not?: string | null;
  link_in?: (string | null)[] | null;
  link_not_in?: (string | null)[] | null;
  link_contains?: string | null;
  link_not_contains?: string | null;
  link_starts_with?: string | null;
  link_not_starts_with?: string | null;
  link_ends_with?: string | null;
  link_not_ends_with?: string | null;
  logo?: string | null;
  logo_not?: string | null;
  logo_in?: (string | null)[] | null;
  logo_not_in?: (string | null)[] | null;
  logo_contains?: string | null;
  logo_not_contains?: string | null;
  logo_starts_with?: string | null;
  logo_not_starts_with?: string | null;
  logo_ends_with?: string | null;
  logo_not_ends_with?: string | null;
  tags?: string | null;
  tags_not?: string | null;
  tags_in?: (string | null)[] | null;
  tags_not_in?: (string | null)[] | null;
  tags_contains?: string | null;
  tags_not_contains?: string | null;
  tags_starts_with?: string | null;
  tags_not_starts_with?: string | null;
  tags_ends_with?: string | null;
  tags_not_ends_with?: string | null;
  publishedBy?: UserWhereInput | null;
  updatedBy?: UserWhereInput | null;
  createdBy?: UserWhereInput | null;
  lang?: Language | null;
  lang_not?: Language | null;
  lang_in?: (Language | null)[] | null;
  lang_not_in?: (Language | null)[] | null;
  scheduledIn_every?: ScheduledOperationWhereInput | null;
  scheduledIn_some?: ScheduledOperationWhereInput | null;
  scheduledIn_none?: ScheduledOperationWhereInput | null;
}

/**
 * The document in stages filter allows specifying a stage entry to cross compare the same document between different stages
 */
export interface PartnerWhereStageInput {
  AND?: PartnerWhereStageInput[] | null;
  OR?: PartnerWhereStageInput[] | null;
  NOT?: PartnerWhereStageInput[] | null;
  stage?: Stage | null;
  compareWithParent?: PartnerWhereComparatorInput | null;
}

/**
 * Identifies documents
 */
export interface ScheduledOperationWhereInput {
  _search?: string | null;
  AND?: ScheduledOperationWhereInput[] | null;
  OR?: ScheduledOperationWhereInput[] | null;
  NOT?: ScheduledOperationWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: (any | null)[] | null;
  createdAt_not_in?: (any | null)[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: (any | null)[] | null;
  updatedAt_not_in?: (any | null)[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  publishedAt?: any | null;
  publishedAt_not?: any | null;
  publishedAt_in?: (any | null)[] | null;
  publishedAt_not_in?: (any | null)[] | null;
  publishedAt_lt?: any | null;
  publishedAt_lte?: any | null;
  publishedAt_gt?: any | null;
  publishedAt_gte?: any | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  errorMessage?: string | null;
  errorMessage_not?: string | null;
  errorMessage_in?: (string | null)[] | null;
  errorMessage_not_in?: (string | null)[] | null;
  errorMessage_contains?: string | null;
  errorMessage_not_contains?: string | null;
  errorMessage_starts_with?: string | null;
  errorMessage_not_starts_with?: string | null;
  errorMessage_ends_with?: string | null;
  errorMessage_not_ends_with?: string | null;
  rawPayload_json_path_exists?: string | null;
  rawPayload_value_recursive?: any | null;
  createdBy?: UserWhereInput | null;
  updatedBy?: UserWhereInput | null;
  publishedBy?: UserWhereInput | null;
  release?: ScheduledReleaseWhereInput | null;
  status?: ScheduledOperationStatus | null;
  status_not?: ScheduledOperationStatus | null;
  status_in?: (ScheduledOperationStatus | null)[] | null;
  status_not_in?: (ScheduledOperationStatus | null)[] | null;
}

/**
 * Identifies documents
 */
export interface ScheduledReleaseWhereInput {
  _search?: string | null;
  AND?: ScheduledReleaseWhereInput[] | null;
  OR?: ScheduledReleaseWhereInput[] | null;
  NOT?: ScheduledReleaseWhereInput[] | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: (any | null)[] | null;
  createdAt_not_in?: (any | null)[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: (any | null)[] | null;
  updatedAt_not_in?: (any | null)[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  publishedAt?: any | null;
  publishedAt_not?: any | null;
  publishedAt_in?: (any | null)[] | null;
  publishedAt_not_in?: (any | null)[] | null;
  publishedAt_lt?: any | null;
  publishedAt_lte?: any | null;
  publishedAt_gt?: any | null;
  publishedAt_gte?: any | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_contains?: string | null;
  title_not_contains?: string | null;
  title_starts_with?: string | null;
  title_not_starts_with?: string | null;
  title_ends_with?: string | null;
  title_not_ends_with?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  errorMessage?: string | null;
  errorMessage_not?: string | null;
  errorMessage_in?: (string | null)[] | null;
  errorMessage_not_in?: (string | null)[] | null;
  errorMessage_contains?: string | null;
  errorMessage_not_contains?: string | null;
  errorMessage_starts_with?: string | null;
  errorMessage_not_starts_with?: string | null;
  errorMessage_ends_with?: string | null;
  errorMessage_not_ends_with?: string | null;
  isActive?: boolean | null;
  isActive_not?: boolean | null;
  isImplicit?: boolean | null;
  isImplicit_not?: boolean | null;
  releaseAt?: any | null;
  releaseAt_not?: any | null;
  releaseAt_in?: (any | null)[] | null;
  releaseAt_not_in?: (any | null)[] | null;
  releaseAt_lt?: any | null;
  releaseAt_lte?: any | null;
  releaseAt_gt?: any | null;
  releaseAt_gte?: any | null;
  createdBy?: UserWhereInput | null;
  updatedBy?: UserWhereInput | null;
  publishedBy?: UserWhereInput | null;
  operations_every?: ScheduledOperationWhereInput | null;
  operations_some?: ScheduledOperationWhereInput | null;
  operations_none?: ScheduledOperationWhereInput | null;
  status?: ScheduledReleaseStatus | null;
  status_not?: ScheduledReleaseStatus | null;
  status_in?: (ScheduledReleaseStatus | null)[] | null;
  status_not_in?: (ScheduledReleaseStatus | null)[] | null;
}

/**
 * This contains a set of filters that can be used to compare values internally
 */
export interface SpecialOfferWhereComparatorInput {
  outdated_to?: boolean | null;
}

/**
 * Identifies documents
 */
export interface SpecialOfferWhereInput {
  _search?: string | null;
  AND?: SpecialOfferWhereInput[] | null;
  OR?: SpecialOfferWhereInput[] | null;
  NOT?: SpecialOfferWhereInput[] | null;
  documentInStages_every?: SpecialOfferWhereStageInput | null;
  documentInStages_some?: SpecialOfferWhereStageInput | null;
  documentInStages_none?: SpecialOfferWhereStageInput | null;
  publishedAt?: any | null;
  publishedAt_not?: any | null;
  publishedAt_in?: (any | null)[] | null;
  publishedAt_not_in?: (any | null)[] | null;
  publishedAt_lt?: any | null;
  publishedAt_lte?: any | null;
  publishedAt_gt?: any | null;
  publishedAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: (any | null)[] | null;
  updatedAt_not_in?: (any | null)[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: (any | null)[] | null;
  createdAt_not_in?: (any | null)[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: (string | null)[] | null;
  name_not_in?: (string | null)[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  description?: string | null;
  description_not?: string | null;
  description_in?: (string | null)[] | null;
  description_not_in?: (string | null)[] | null;
  description_contains?: string | null;
  description_not_contains?: string | null;
  description_starts_with?: string | null;
  description_not_starts_with?: string | null;
  description_ends_with?: string | null;
  description_not_ends_with?: string | null;
  link?: string | null;
  link_not?: string | null;
  link_in?: (string | null)[] | null;
  link_not_in?: (string | null)[] | null;
  link_contains?: string | null;
  link_not_contains?: string | null;
  link_starts_with?: string | null;
  link_not_starts_with?: string | null;
  link_ends_with?: string | null;
  link_not_ends_with?: string | null;
  logo?: string | null;
  logo_not?: string | null;
  logo_in?: (string | null)[] | null;
  logo_not_in?: (string | null)[] | null;
  logo_contains?: string | null;
  logo_not_contains?: string | null;
  logo_starts_with?: string | null;
  logo_not_starts_with?: string | null;
  logo_ends_with?: string | null;
  logo_not_ends_with?: string | null;
  tags?: string | null;
  tags_not?: string | null;
  tags_in?: (string | null)[] | null;
  tags_not_in?: (string | null)[] | null;
  tags_contains?: string | null;
  tags_not_contains?: string | null;
  tags_starts_with?: string | null;
  tags_not_starts_with?: string | null;
  tags_ends_with?: string | null;
  tags_not_ends_with?: string | null;
  subtitle?: string | null;
  subtitle_not?: string | null;
  subtitle_in?: (string | null)[] | null;
  subtitle_not_in?: (string | null)[] | null;
  subtitle_contains?: string | null;
  subtitle_not_contains?: string | null;
  subtitle_starts_with?: string | null;
  subtitle_not_starts_with?: string | null;
  subtitle_ends_with?: string | null;
  subtitle_not_ends_with?: string | null;
  publishedBy?: UserWhereInput | null;
  updatedBy?: UserWhereInput | null;
  createdBy?: UserWhereInput | null;
  lang?: Language | null;
  lang_not?: Language | null;
  lang_in?: (Language | null)[] | null;
  lang_not_in?: (Language | null)[] | null;
  scheduledIn_every?: ScheduledOperationWhereInput | null;
  scheduledIn_some?: ScheduledOperationWhereInput | null;
  scheduledIn_none?: ScheduledOperationWhereInput | null;
}

/**
 * The document in stages filter allows specifying a stage entry to cross compare the same document between different stages
 */
export interface SpecialOfferWhereStageInput {
  AND?: SpecialOfferWhereStageInput[] | null;
  OR?: SpecialOfferWhereStageInput[] | null;
  NOT?: SpecialOfferWhereStageInput[] | null;
  stage?: Stage | null;
  compareWithParent?: SpecialOfferWhereComparatorInput | null;
}

/**
 * This contains a set of filters that can be used to compare values internally
 */
export interface UserWhereComparatorInput {
  outdated_to?: boolean | null;
}

/**
 * Identifies documents
 */
export interface UserWhereInput {
  _search?: string | null;
  AND?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  NOT?: UserWhereInput[] | null;
  documentInStages_every?: UserWhereStageInput | null;
  documentInStages_some?: UserWhereStageInput | null;
  documentInStages_none?: UserWhereStageInput | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: (string | null)[] | null;
  id_not_in?: (string | null)[] | null;
  id_contains?: string | null;
  id_not_contains?: string | null;
  id_starts_with?: string | null;
  id_not_starts_with?: string | null;
  id_ends_with?: string | null;
  id_not_ends_with?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_in?: (any | null)[] | null;
  createdAt_not_in?: (any | null)[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_in?: (any | null)[] | null;
  updatedAt_not_in?: (any | null)[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  publishedAt?: any | null;
  publishedAt_not?: any | null;
  publishedAt_in?: (any | null)[] | null;
  publishedAt_not_in?: (any | null)[] | null;
  publishedAt_lt?: any | null;
  publishedAt_lte?: any | null;
  publishedAt_gt?: any | null;
  publishedAt_gte?: any | null;
  name?: string | null;
  name_not?: string | null;
  name_in?: (string | null)[] | null;
  name_not_in?: (string | null)[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  picture?: string | null;
  picture_not?: string | null;
  picture_in?: (string | null)[] | null;
  picture_not_in?: (string | null)[] | null;
  picture_contains?: string | null;
  picture_not_contains?: string | null;
  picture_starts_with?: string | null;
  picture_not_starts_with?: string | null;
  picture_ends_with?: string | null;
  picture_not_ends_with?: string | null;
  isActive?: boolean | null;
  isActive_not?: boolean | null;
  kind?: UserKind | null;
  kind_not?: UserKind | null;
  kind_in?: (UserKind | null)[] | null;
  kind_not_in?: (UserKind | null)[] | null;
}

/**
 * The document in stages filter allows specifying a stage entry to cross compare the same document between different stages
 */
export interface UserWhereStageInput {
  AND?: UserWhereStageInput[] | null;
  OR?: UserWhereStageInput[] | null;
  NOT?: UserWhereStageInput[] | null;
  stage?: Stage | null;
  compareWithParent?: UserWhereComparatorInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
