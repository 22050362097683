import { gql } from '@apollo/client';

export const GET_WHITELABEL_CONFIG = gql`
  query getWhitelabelConfig($client: String) {
    whitelabel(where: { client: $client }) {
      client
      defaultLanguage
      communityLink
      communityLinkLabel
      customPartners
      linksToQuiz
      isLinkToPartnersVisible

      logo {
        height
        url
        width
      }

      linkToFeedbackForm {
        id
        label
        url
      }
    }
  }
`;
