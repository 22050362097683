import { ReactNode } from 'react';

import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Slide } from '@mui/material';

type Props = {
  children: ReactNode;
};
export const SnackProvider = (props: Props) => {
  const { children } = props;

  return (
    <SnackbarProvider
      maxSnack={10}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionComponent={Slide}
    >
      {children}
    </SnackbarProvider>
  );
};
