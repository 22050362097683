export const size = {
  breakpoints: {
    // xs: 0,
    // sm: 480,
    // md: 768,
    // lg: 992,
    // xl: 1200,

    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};
