import { PhaseStage, UserRole } from '../../../__generated__/globalTypes';
import { EnumLocalStorage } from '../enums/generalEnums';

export const isAdmin = (user) =>
  user?.role === UserRole.ADMIN || user?.role === UserRole.SUPER_ADMIN;

export const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === 'production';
export const isStaging = process.env.NEXT_PUBLIC_NODE_ENV === 'staging';
export const isDev = process.env.NEXT_PUBLIC_NODE_ENV === 'localhost';

export const setLastVisitedPhase = (phase: PhaseStage) =>
  window.localStorage.setItem(EnumLocalStorage.LastVisitedDiaryStage, phase);

export const getLastVisitedPhase = (): string =>
  window.localStorage.getItem(EnumLocalStorage.LastVisitedDiaryStage);
