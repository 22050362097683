/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthProvider {
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  LINKEDIN = 'LINKEDIN',
}

export enum DiaryStatus {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum EmailVerification {
  DONE = 'DONE',
  NOT_NEEDED = 'NOT_NEEDED',
  PENDING = 'PENDING',
}

export enum PhaseStage {
  BUSINESS = 'BUSINESS',
  IDEA = 'IDEA',
  IDEATION = 'IDEATION',
  MVP = 'MVP',
  PROTOTYPE = 'PROTOTYPE',
}

export enum ProjectPhase {
  FINISHED_PRODUCT = 'FINISHED_PRODUCT',
  IDEALIZATION = 'IDEALIZATION',
  INVESTMENT_READY = 'INVESTMENT_READY',
  PROTOTYPING = 'PROTOTYPING',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export interface OnboardingInputs {
  question: string;
  answer: string[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
