import { EnumAuthCookies } from '../enums/generalEnums';
import nookies, { destroyCookie } from 'nookies';
import { ApolloClient } from '@apollo/client';
import { NextPageContext } from 'next';
import { initializeApollo } from '../../apollo/apollo-client';

export const getStoredAccessToken = (ctx?: NextPageContext) =>
  nookies.get(ctx || {})[EnumAuthCookies.AccessToken];

const removeStoredTokens = (ctx?: NextPageContext) => {
  nookies.destroy(ctx, EnumAuthCookies.AccessToken, {
    path: '/',
  });
  nookies.destroy(ctx, EnumAuthCookies.RefreshToken, {
    path: '/',
  });
  // destroyCookie(ctx, EnumAuthCookies.AccessToken, {
  //   path: '/',
  // });
  // destroyCookie(ctx, EnumAuthCookies.RefreshToken, {
  //   path: '/',
  // });
};

export const storeNewTokens = (
  accessToken: string,
  refreshToken: string,
  expiresIn?: number | null,
  refreshExpiresIn?: number | null
): void => {
  nookies.set({}, EnumAuthCookies.AccessToken, accessToken, {
    maxAge: expiresIn,
    path: '/',
  });
  nookies.set({}, EnumAuthCookies.RefreshToken, refreshToken, {
    maxAge: refreshExpiresIn,
    path: '/',
  });
};

export const signIn = async (
  accessToken: string | null,
  refreshToken: string | null,
  expiresIn?: number | null,
  refreshExpiresIn?: number | null,
  client?: ApolloClient<any>
): Promise<void> => {
  if (accessToken && refreshToken) {
    storeNewTokens(accessToken, refreshToken, expiresIn, refreshExpiresIn);
  }

  // await client?.resetStore();
};

export const logOut = async (routeToLogin): Promise<void> => {
  const apolloClient = initializeApollo();

  await removeStoredTokens();

  await apolloClient.clearStore();
  routeToLogin();
};
